var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({ height: '100%' })},[_c('v-card',[_c('v-card-title',[_vm._v(" Problems "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":15,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.problemId",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"subtitle-1",attrs:{"href":'/problem/'+item.problemId,"rel":"noopener noreferrer","target":"_blank"}},[_vm._v(_vm._s(item.problemId))])]}},{key:"item.problemName",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"subtitle-1",attrs:{"href":'/problem/'+item.problemId,"rel":"noopener noreferrer","target":"_blank"}},[_vm._v(_vm._s(item.problemName))])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag){return _c('v-chip',{key:tag,staticClass:"mx-1",attrs:{"label":"","small":""}},[_vm._v(_vm._s(tag))])})}},{key:"item.statistic",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":("/problem/" + (item.problemId) + "/statistic"),"rel":"noopener noreferrer","target":"_blank","color":"info","small":"","text":""}},[_c('v-icon',[_vm._v("mdi-chart-arc")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }